import { Component, Prop } from 'vue-property-decorator';
import PlayerApi from '@/support/playerApi';
import { ServerDataModule } from '@/store/serverData';
import Util from '@/support/utility';
import { IWizardManager } from '@/interfaces';
import StudioPageBase from '@/support/studioPageBase';

@Component
export default class SettingsComponent extends StudioPageBase {
    get serverData() {
        return ServerDataModule.serverData;
    }

    options = {
    };

    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>{} })
    parent: any;

    created() {
        Debug.setDebugModule('Settings', this);

        super.created('settings', true);
    }

    mounted() {
        super.mounted();
    }

    showExpired() {
        this.done();
    }

    showError(error: string, error2: string = null) {
        this.done();
    }

    done() {
        if (!this.wizard) return;

        this.wizard.back();
    }

    cancel() {
        if (!this.wizard) return;

        this.wizard.back();
    }

    addNewStudio() {
        if (!this.wizard) return;

        this.wizard.navigateTo('addNewStudio');
    }

    resetAssets() {
        let $refs = <any>this.$refs;
        $refs.refreshVideosPrompt.show();
    }

    // ReSharper disable once InconsistentNaming
    async _resetAssets() {
        Debug.log('resetAssets starting');

        try {
            let response = await PlayerApi.resetAssets();

            Debug.log("resetAssets SUCCESS", response);
            Util.showToast('Refreshing all videos');
            return true;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("resetAssets FAILED", err);
            Util.showToast('Failed to refresh all videos', true);
            return null;
        } finally {

        }
    }

    async resetStudios(event: any = null) {
        Debug.log('resetStudios starting');

        try {
            let response = await PlayerApi.resetStudios();
            Debug.log("resetStudios SUCCESS", response);
            Util.showToast('Refreshed all portals');
            return true;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("resetStudios FAILED", message);
            Util.showToast('Failed to refresh all portals', true);
            return null;
        } finally {

        }
    }

    refreshVideosClosed(result) {
        Debug.log('refreshVideosClosed', result);

        if (result) {
            this._resetAssets();
        }

        return result;
    }
}
